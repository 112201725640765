import React from 'react'
import neon_bg from '../../assets/svgs/logo-neon-bg.svg'
import logo_bg from '../../assets/svgs/logo-bg.svg'
import logo from '../../assets/svgs/logo.svg'

const LoadingScreen = () => {
    return (
        <div className='flex flex-column justify-center items-center relative bg-darkBlue h-screen text-white animate-fade'>
            <div className='flex flex-column scale justify-center items-center relative scale-75'>
                <img className='animate-pulse' src={neon_bg} alt="" />
                <img className='absolute' src={logo_bg} alt="" />
                <img className='absolute -translate-x-2' src={logo} alt="" />
            </div>
        </div>
    )
}

export default LoadingScreen