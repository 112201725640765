import React, { Suspense } from "react";
import LoadingScreen from "./components/loading/LoadingScreen";


const Router = React.lazy(() => import('./routes/Router'))

function App() {

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Router />
    </Suspense>
  );
}

export default App;
